import React, { Component } from 'react';
import firebase from 'gatsby-plugin-firebase';
import { navigate } from 'gatsby';
import Loader from '../pages/admin/modal-overlays/loader';

class ReportListing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reports: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    this.getReportList();
  }

  getReportList = () => {
    //get only those reports whose product matches selected product and whose time >= report date
    let _self = this;
    this.setState({ isLoading: true });
    firebase
      .firestore()
      .collection('reports')
      .where('selectedProduct.value', '==', localStorage.getItem('product_id'))
      .where('published', '==', 'yes')
      .get()
      .then((snapshot) => {
        let tempArr = [];
        for (let i in snapshot.docs) {
          let doc = snapshot.docs[i];
          if (doc.data().reportDate.seconds >= localStorage.getItem('time')) {
            tempArr.push({
              id: doc.id,
              title: doc.data().ov_title,
              reportDate: _self.formatDate(doc.data().reportDate.seconds),
              reportTime: doc.data().selectedTime.label,
            });
          }
        }
        _self.setState({ isLoading: false, reports: tempArr });
      })
      .catch((e) => {
        _self.setState({ isLoading: false });
      });
  };

  formatDate = (seconds) => {
    let date = new Date(seconds * 1000);
    return `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;
  };

  viewReport = (id) => {
    navigate(`/single-report/${id}`);
  };

  render() {
    return (
      <>
        <div className="px-5">
          <button className="btn-back" onClick={() => navigate('/app')}>
            &laquo; Back
          </button>
        </div>

        <div className="container-fluid mt-1 px-5">
          <h3>Report List</h3>
          <div className="table-responsive">
            <table className="table table-hover w-100">
              <thead className="thead-dark">
                <tr>
                  <th>Overview Title</th>
                  <th>Report Date</th>
                  <th>Report Time</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {this.state.reports.length ? (
                  this.state.reports.map((report, i) => (
                    <tr key={i}>
                      <td>{report.title}</td>
                      <td>{report.reportDate}</td>
                      <td>{report.reportTime}</td>
                      <td>
                        <button className="btn btn-primary btn-sm" onClick={() => this.viewReport(report.id)}>
                          View Report
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4">No Reports</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <Loader isOpen={this.state.isLoading} />
        </div>
      </>
    );
  }
}

export default ReportListing;
